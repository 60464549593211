import { useState, useEffect } from 'react'
import Main from '../../layouts/Main'
import Card from '../../components/Card'
import Pagination from '../../components/Pagination'
import InputGroup from '../../components/InputGroup'
import RefundCustomerService from '../../services/RefundCustomer'
import RefundCustomerItem from './RefundCustomerItem'
import Confirm from './Confirm'
import Edit from './Edit'

const RefundCustomerList = () => {
  const [customers, setCustomers] = useState([])
  const [isUpdated, setIsUpdated] = useState(false)
  const [modal, setModal] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [data, setData] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [search, setSearch] = useState(null)
  const [totalPage, setTotalPage] = useState(0)

  const limit = 10

  useEffect(() => {
    RefundCustomerService.listRefundCustomer(pageNumber, limit, search)
      .then((res) => {
        setCustomers(res.data.data)
        setTotalPage(res.data.data.total_page)
        setIsUpdated(false)
      })
      .catch((err) => {
        console.log(err)
        setIsUpdated(false)
      })
  }, [isUpdated])

  const searchHandler = (value) => {
    setSearch(value)
    setPageNumber(1)
    setIsUpdated(true)
  }

  const confirmHandler = (data) => {
    setData(data)
    setModal(true)
  }

  const editHandler = (data) => {
    setData(data)
    setModalEdit(true)
  }

  const closeEditHandler = () => {
    setModal(false)
    setModalEdit(false)
  }

  const changePageHandler = ({ selected }) => {
    setPageNumber(selected + 1)
    setIsUpdated(true)
  }

  return (
    <Main title="Customer Refund">
      {modal && (
        <Confirm
          data={data}
          activeModal={modal}
          setIsUpdated={setIsUpdated}
          closeModal={closeEditHandler}
        />
      )}

      {modalEdit && (
        <Edit
          data={data}
          activeModal={modalEdit}
          setIsUpdated={setIsUpdated}
          closeModal={closeEditHandler}
        />
      )}

      <Card col={'col-xl-12 col-lg-12'}>
        <div className="row justify-content-end">
          <div className="col-5">
            <InputGroup
              button="btn-primary"
              icon="fa fa-search"
              searchHandler={searchHandler}
            />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table text-nowrap text-md-nowrap text-center mb-0">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Project ID</th>
                    <th>Client Name</th>
                    <th>Amount</th>
                    {/* <th>Payment Method</th> */}
                    <th>Fee Admin</th>
                    <th>Fee Refund</th>
                    <th>Percentage</th>
                    <th>Total Refund</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {customers.map((customer, index) => (
                    <RefundCustomerItem
                      key={customer.id}
                      no={index + 1}
                      data={customer}
                      confirmHandler={confirmHandler}
                      editHandler={editHandler}
                      setUpdated={setIsUpdated}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col">
            {totalPage > 0 && (
              <Pagination
                pageCount={totalPage}
                changePage={changePageHandler}
              />
            )}
          </div>
        </div>
      </Card>
    </Main>
  )
}

export default RefundCustomerList
