import { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button'
import AuthService from '../../services/Auth'
import { AuthContext } from '../../context/AuthContext'
import Alert from '../../components/Alert'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(false)

  const navigate = useNavigate()
  const { dispatch } = useContext(AuthContext)

  const loginHandler = async (e) => {
    e.preventDefault()
    setLoading(true)

    AuthService.login(email, password)
      .then((res) => {
        const user = {
          id: res.data.data[0].id,
          email: res.data.data[0].email,
          token: res.data.data[0].token,
        }
        dispatch({ type: 'LOGIN', payload: user })
        setLoading(false)
        navigate('/')
      })
      .catch((err) => {
        setLoading(false)
        setAlert(true)
        console.log(err)
      })
  }

  return (
    <div className="container-login100">
      <div className="wrap-login100 p-7">
        <form className="login100-form validate-form" onSubmit={loginHandler}>
          <div className="text-center">
            <img
              src={`${window.location.origin}/assets/images/logo/logo-new2.png`}
              style={{ width: '50%' }}
            />
          </div>

          {alert && <Alert type="danger" title="Wrong email or password" />}

          <div className="panel panel-primary">
            <div className="panel-body tabs-menu-body p-0 pt-5">
              <div className="tab-content">
                <div className="tab-pane active" id="tab5">
                  <div
                    className="wrap-input100 validate-input input-group"
                    data-bs-validate="Valid email is required: ex@abc.xyz"
                  >
                    <a
                      href="javascript:void(0)"
                      className="input-group-text bg-white text-muted"
                    >
                      <i
                        className="zmdi zmdi-email text-muted"
                        aria-hidden="true"
                      />
                    </a>
                    <input
                      className="input100 border-start-0 form-control ms-0"
                      type="email"
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div
                    className="wrap-input100 validate-input input-group"
                    id="Password-toggle"
                  >
                    <a
                      href="javascript:void(0)"
                      className="input-group-text bg-white text-muted"
                    >
                      <i
                        className="zmdi zmdi-eye text-muted"
                        aria-hidden="true"
                      />
                    </a>
                    <input
                      className="input100 border-start-0 form-control ms-0"
                      type="password"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  <div className="container-login100-form-btn">
                    <Button
                      button="btn-primary login100-form-btn"
                      title="Login"
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login
