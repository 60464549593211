import Button from '../../components/Button'
import Badge from '../../components/Badge'
import { convertToRupiah } from '../../helper/RupiahDashboard'
import 'react-toastify/dist/ReactToastify.css'

const RefundCustomerItem = (props) => {
  const data = props.data

  const buttonHandler = () => {
    props.confirmHandler(data)
  }

  const editHandler = () => {
    props.editHandler(data)
  }

  return (
    <tr>
      <td>{props.no}</td>
      <td>{data.project_id}</td>
      <td>{data.client_name}</td>
      <td>Rp. {convertToRupiah(data.amount)}</td>
      {/* <td>{data.payment_method}</td> */}
      <td className="text-danger">
        Rp. {convertToRupiah(data.fee_admin == null ? 0 : data.fee_admin)}
      </td>
      <td className="text-danger">Rp. {convertToRupiah(data.fee)}</td>
      <td>{data.percent}%</td>
      <td>Rp. {convertToRupiah(data.total_refund)}</td>
      <td className="text-center align-middle">
        {(data.is_refund == null || data.is_refund == 0) && (
          <div>
            <Button
              button="btn-warning btn-sm me-1"
              icon="fa fa-edit"
              onClick={editHandler}
            />
            <Button
              button="btn-success btn-sm"
              icon="fa fa-check"
              onClick={buttonHandler}
            />
          </div>
        )}
        {data.is_refund == 1 && (
          <Badge
            name="Terbayar"
            badge={`bg-dark py-2 px-4`}
            type="rounded-pill"
          />
        )}
      </td>
    </tr>
  )
}

export default RefundCustomerItem
