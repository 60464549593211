import axios from 'axios'
import { headersTokenConfig } from '../config/HeaderToken'

class WorkerRefundService {
  listRefundCustomer(page, limit, search) {
    const endPoint = `/ticketing/refund-customer?page=${page}&limit=${limit}`
    let url = process.env.REACT_APP_API_URL_V1 + endPoint

    if (search) {
      url = process.env.REACT_APP_API_URL_V1 + endPoint + `&search=${search}`
    }

    return axios.get(url, headersTokenConfig)
  }

  confirm(project_id) {
    const endPoint = `/ticketing/refund-customer/${project_id}/confirm`
    const url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.put(url, null, headersTokenConfig)
  }

  edit(project_id, total_refund) {
    const endPoint = `/ticketing/refund-customer/${project_id}/update`
    const url = process.env.REACT_APP_API_URL_V1 + endPoint

    return axios.put(url, { total_refund }, headersTokenConfig)
  }
}

export default new WorkerRefundService()
