import { toast } from 'react-toastify'
import Modal from '../../components/Modal'
import { Toast } from '../../config/Toast'
import RefundCustomerService from '../../services/RefundCustomer'
import 'react-toastify/dist/ReactToastify.css'

const Confirm = (props) => {
  const data = props.data
  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal
    ? { display: 'block', paddingright: '17px' }
    : ''

  const notifySuccess = (message) => toast.success(message, Toast)
  const notifyFailed = (message) => toast.error(message, Toast)

  const submitHandler = () => {
    if (
      data.total_refund <= 0 ||
      data.total_refund == null ||
      data.total_refund == ''
    ) {
      notifyFailed('Total refund tidak boleh kurang dari sama dengan 0')
      props.closeModal()
      return
    }

    props.closeModal()

    RefundCustomerService.confirm(data.project_id)
      .then(() => {
        notifySuccess('Berhasil konfirmasi refund customer')
        props.setIsUpdated(true)
      })
      .catch((err) => {
        console.log(err)
        notifyFailed('Terjadi kesalahan')
      })
  }

  return (
    <Modal
      activeModal={activeModal}
      activeStyle={activeStyle}
      title="Posting Perhitungan Upah"
      closeClick={props.closeModal}
      submitHandler={submitHandler}
      type="Ya, Konfirmasi"
    >
      <h5>
        Apakah anda yakin telah membayar refund customer ? <br />
        <br />
        <div className="fw-bold">
          Setelah proses ini, <br /> data sudah tidak bisa lagi di kembalikan
          semula.
        </div>
      </h5>
    </Modal>
  )
}

export default Confirm
